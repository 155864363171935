import { EnvType, UserSchema } from '../services/api';

export const storageKeys = {
  app: 'app',
  user: 'user',
  polling: 'polling',
  propFilters: 'prop_filters',
  generationTask: 'generation_task',
  userPreferences: 'user_preferences',
  appPageNotifications: 'app_page_notification'
};

export const getStorageItem = (key: string) => {
  if (typeof window !== 'undefined') {
    const item = window.localStorage.getItem(key);

    try {
      return item ? JSON.parse(item) : null;
    } catch {
      return null;
    }
  }
};

export const setStorageItem = <T>(key: string, value: T) => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
};

export const removeStorageItem = (key: string) => {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem(key);
  }
};

export const clearLocalStorage = () => {
  if (typeof window !== 'undefined') {
    window.localStorage.clear();
  }
};

export const getAppStorage = () => {
  const appStorage = getStorageItem(storageKeys.app);

  return {
    appId: appStorage?.appId || 0,
    appName: appStorage?.appName || '',
    versionId: appStorage?.versionId || 0,
    versionName: appStorage?.versionName || '',
    type: (appStorage?.type as EnvType) || EnvType['EVAL'],
    timestamp: appStorage?.timestamp || { start: NaN, end: NaN }
  };
};

export const getUserStorage = () => {
  const userStorage: UserSchema = getStorageItem(storageKeys.user);

  return {
    plan: 'Trial' || 'Paid', // Todo - Take from user Storage once we have this info
    email: userStorage?.email || '',
    name: userStorage?.name || '',
    organization_name: userStorage?.organization_name || ''
  };
};

export const getUserPreferences = () => {
  const userPreferencesStorage = getStorageItem(storageKeys.userPreferences);

  return {
    lightBar: userPreferencesStorage?.lightBar || false,
    darkMode: userPreferencesStorage?.darkMode || false,
    activeEnvs: userPreferencesStorage?.activeEnvs || [EnvType['EVAL'], EnvType['PROD']]
  };
};
