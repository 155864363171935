import React, { useState, useEffect } from 'react';

import { FormControl, SvgIconTypeMap, SxProps } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import { Input } from '../Input/Input';

import {
  StyledSelect,
  menuProps,
  StyledStickyContainer,
  StyledBottomContainer,
  StyledBottomTextContainer,
  StyledValueContainer,
  StyledText,
  StyledPlaceholderText,
  sxStyles
} from './Dropdown.styles';

import { Text, TextTypes } from '../Text/Text';
import { DropdownItem } from './DropdownItem';

interface DropdownProps {
  id?: string;
  sx?: SxProps;
  whiteBg?: boolean;
  dataTestid?: string;
  label?: string;
  searchPlaceholder?: string;
  minWidth?: string;
  textType?: string;
  disabled?: boolean;
  noEmptyText?: boolean;
  data: {
    name: string;
    amount?: string;
  }[];
  value: string | undefined;
  setValue: (value: string) => void;
  searchField?: boolean;
  isEnvSelect?: boolean;
  iconComponent?: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & { muiName: string };
  bottomBtn?: {
    label?: string;
    icon?: React.ReactNode;
    action: () => void;
    secondary?: {
      label: string;
      icon: React.ReactNode;
      action: () => void;
    };
  };
}

export const Dropdown = (props: DropdownProps) => {
  const {
    label = '',
    dataTestid,
    data,
    value,
    setValue,
    searchField,
    searchPlaceholder,
    bottomBtn,
    isEnvSelect,
    disabled,
    whiteBg,
    minWidth,
    noEmptyText,
    textType,
    iconComponent,
    ...otherProps
  } = props;

  const [open, setOpen] = useState(false);
  const [state, setState] = useState(data);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (!inputValue) setState(data);
  }, [inputValue, data]);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setState(data);
  };

  const handleChange = (name: string) => {
    setValue(name);
    handleClose();
    setInputValue('');
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setInputValue(value);
    setState(data.filter(item => item.name.toLowerCase().includes(value.toLowerCase().trim())));
  };

  const resetInput = () => {
    setInputValue('');
    setState(data);
  };

  const renderValue = isEnvSelect ? (
    <StyledValueContainer>
      <Text text={value?.split('|')[0]} /> <StyledText text={`| ${value?.split('|')[1]}`} />
    </StyledValueContainer>
  ) : textType ? (
    <Text text={value} type={textType as TextTypes} />
  ) : (
    value
  );

  const placeholderText = <StyledPlaceholderText text={label} type={textType as TextTypes} />;

  return (
    <FormControl>
      <StyledSelect
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        data-testid={dataTestid ?? 'dropdown'}
        IconComponent={iconComponent || ExpandMoreIcon}
        MenuProps={menuProps(!!searchField, whiteBg, minWidth)}
        renderValue={() => (value && value !== 'undefined' ? renderValue : placeholderText)}
        disabled={disabled}
        autoWidth
        displayEmpty
        {...otherProps}
      >
        {searchField && data.length && (
          <StyledStickyContainer is_white_bg={whiteBg ? 'true' : 'false'}>
            <Input
              value={inputValue}
              onChange={handleSearch}
              onCloseIconClick={resetInput}
              placeholder={`Search ${searchPlaceholder || label}`}
              onKeyDown={e => e.stopPropagation()}
              onClick={e => e.stopPropagation()}
              searchField
              sx={sxStyles}
            />
          </StyledStickyContainer>
        )}
        <DropdownItem
          state={state}
          inputValue={inputValue}
          selectedValue={value}
          handleChange={handleChange}
          isEnvSelect={isEnvSelect}
          isNoEmptyText={Boolean(noEmptyText && !data.length)}
        />
        {bottomBtn?.label && (
          <StyledBottomContainer
            is_white_bg={whiteBg ? 'true' : 'false'}
            is_no_data={data.length ? 'false' : 'true'}
            is_no_border={Boolean(noEmptyText && !data.length).toString()}
          >
            <StyledBottomTextContainer onClick={() => bottomBtn.action()} data-testid={bottomBtn?.label}>
              {bottomBtn?.icon && bottomBtn.icon}
              <Text text={bottomBtn?.label} type="bodyBold" />
            </StyledBottomTextContainer>
            {bottomBtn?.secondary && (
              <StyledBottomTextContainer
                onClick={() => bottomBtn.secondary?.action()}
                data-testid={bottomBtn.secondary.label}
              >
                {bottomBtn.secondary.icon}
                <Text text={bottomBtn.secondary.label} type="h3" />
              </StyledBottomTextContainer>
            )}
          </StyledBottomContainer>
        )}
      </StyledSelect>
    </FormControl>
  );
};
