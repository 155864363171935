import { styled } from '@mui/material';

import { StyledButton, StyledContainer } from '@/components/lib';

export const StyledCategoriesContainer = styled(StyledContainer)({
  padding: 0,
  paddingTop: '16px',
  gap: '24px'
});

export const StyledCategoriesSearchContainer = styled(StyledContainer)({
  width: '100%',
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
  padding: 0,
  gap: '32px'
});

export const StyledCategoriesButtonsContainer = styled(StyledContainer)({
  alignItems: 'center',
  flexDirection: 'row',
  gap: '8px',
  width: 'auto',
  padding: 0
});

export const StyledNextButton = styled(StyledButton)({
  margin: '24px auto 0',
  height: '42px',
  width: '138px'
});

export const StyledCategoriesListContainer = styled(StyledContainer)({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 180px))',
  gridTemplateRows: 'repeat(auto-fill, minmax(56px, 56px))',
  gap: '8px',
  padding: '0',
  height: '350px',
  overflow: 'auto',
  borderRadius: 0
});

export const StyledCategoriesListItem = styled(StyledContainer)<{ selected: boolean }>(({ theme, selected }) => ({
  flexDirection: 'row',
  height: '56px',
  maxWidth: '180px',
  padding: '13px 12px',
  alignItems: 'center',
  cursor: 'pointer',
  transition: '0.5s',
  borderRadius: '8px',
  border: `2px solid ${selected ? theme.palette.primary.main : theme.palette.grey[500]}`,

  ' p': {
    color: selected ? theme.palette.primary.main : theme.palette.grey[500],
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  ':hover': {
    borderColor: theme.palette.primary.main
  },

  ':hover p': {
    color: theme.palette.primary.main
  }
}));

export const sxStyles = { flex: 2 };
