import React from 'react';

import {
  HomeOutlined,
  TuneOutlined,
  ListAltRounded,
  ContentCopyOutlined,
  ThumbUpOffAltOutlined
} from '@mui/icons-material';

import { theme } from '@/components/lib/theme';

export const routes = {
  overview: '/',
  samples: '/samples',
  onboarding: '/onboarding',
  licenseAgreement: '/license',
  workspaceSettings: '/workspace-settings',
  config: {
    apiKey: '/configuration/api-key',
    applications: '/configuration/applications',
    versions: '/configuration/versions',
    autoAnnotation: '/configuration/auto-annotation',
    properties: '/configuration/properties'
  },
  files: {
    exampleCsvFileQA: '/exampleQA.csv',
    exampleCsvFileSummarization: '/exampleSummarization.csv',
    exampleCsvFileGeneration: '/exampleGeneration.csv',
    examplePentestCsvFile: '/example-pentest.csv',
    annotationYmlFile: '/config.yml'
  }
};

export const routesMetaData = {
  title: 'Deepchecks | ',
  overview: 'Deepchecks LLM Evaluation',
  samples: 'Data',
  onboarding: 'Onboarding',
  license: 'License',
  notFound: '404',
  workspaceSettings: 'Workspace Settings',
  apiKey: 'Api Key',
  applications: 'Applications',
  properties: 'Properties',
  versions: 'Versions',
  annotationConfig: 'Annotation Config'
};

const inactiveLinkStyles = {
  color: theme.palette.grey[500]
};

export const sidebarRoutes = [
  {
    route: routes.overview,
    icon: <HomeOutlined sx={inactiveLinkStyles} />,
    activeIcon: <HomeOutlined color="primary" />,
    linkLabel: 'Overview'
  },
  {
    route: routes.samples,
    icon: <ListAltRounded sx={inactiveLinkStyles} />,
    activeIcon: <ListAltRounded color="primary" />,
    linkLabel: 'Data'
  },
  {
    route: routes.config.versions,
    icon: <ContentCopyOutlined sx={inactiveLinkStyles} />,
    activeIcon: <ContentCopyOutlined color="primary" />,
    linkLabel: 'Versions'
  },
  {
    route: routes.config.properties,
    icon: <TuneOutlined sx={inactiveLinkStyles} />,
    activeIcon: <TuneOutlined color="primary" />,
    linkLabel: 'Properties'
  },
  {
    route: routes.config.autoAnnotation,
    icon: <ThumbUpOffAltOutlined sx={inactiveLinkStyles} />,
    activeIcon: <ThumbUpOffAltOutlined color="primary" />,
    linkLabel: 'Annotation Config'
  }
];
