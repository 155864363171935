import Axios, { AxiosError, AxiosRequestConfig } from 'axios';

import qs from 'qs';

import { getAuthUrl } from '../utils/getAuthUrl';

export const AXIOS_INSTANCE = Axios.create({ baseURL: process.env.NEXT_PUBLIC_BASE_API, withCredentials: true });

AXIOS_INSTANCE.defaults.paramsSerializer = params => qs.stringify(params, { arrayFormat: 'repeat' });

AXIOS_INSTANCE.interceptors.response.use(
  response => response,
  error => {
    const { response } = error;

    if (response) {
      if (response.status === 401) {
        window.location.href = getAuthUrl();
      } else if (response.status === 504) {
        return null;
      } else {
        return response;
      }
    }
  }
);

let cancelTokenSource = Axios.CancelToken.source();

export const cancelPendingRequests = () => {
  cancelTokenSource.cancel();
  cancelTokenSource = Axios.CancelToken.source();
};

export const customInstance = async <T>(config: AxiosRequestConfig): Promise<T> => {
  const promise = await AXIOS_INSTANCE({ ...config, cancelToken: cancelTokenSource.token })
    .then(({ data }) => data)
    .catch(() => '' /* logger */);

  return promise;
};

export type ErrorType<Error> = AxiosError<Error>;
