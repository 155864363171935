import { Checkbox, styled, Theme } from '@mui/material';

export const sxBorderStyles = (theme: Theme) => ({ height: '1px', background: theme.palette.grey[200], width: '100%' });
export const sxLabelStyles = (disabled?: boolean) => ({
  margin: '2px 0 0 -2px',
  opacity: disabled ? 0.5 : 1
});

export const MUIStyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.grey[200]
}));
