import React, { useEffect, useState } from 'react';

import { Stack } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

import {
  CheckboxDropdownContainer,
  CheckboxDropdownDropdown,
  StyledEmptyList,
  StyledLabelText,
  StyledCheckboxContainer,
  StyledPopover,
  StyledSearchInput,
  StyledChip
} from './CheckboxDropdown.styles';

import { Text } from '../../Text/Text';
import { Checkbox } from '../../Button/Checkbox/Checkbox';

interface DropdownProps {
  label?: string;
  value: string[];
  options: string[];
  disabled?: boolean;
  placeholder?: string;
  searchField?: boolean;
  searchFieldLabel?: string;
  setValue: (value: string[]) => void;
}

const constants = { noOptionsText: 'Not found any options' };

export const CheckboxDropdown = (props: DropdownProps) => {
  const { label = '', placeholder, options, value, searchField, disabled, searchFieldLabel, setValue } = props;

  const [inputValue, setInputValue] = useState('');
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [curOptions, setCurOptions] = useState<{ value: string; label?: string }[]>([]);

  const handleInputCloseIconClick = () => setInputValue('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => setInputValue(event.target.value);

  const optionsWithCurFormat = options.map(el => ({ value: el, label: el }));

  useEffect(() => {
    if (options) {
      setCurOptions(
        optionsWithCurFormat.filter(
          el =>
            el.value.toLowerCase().includes(inputValue.toLowerCase()) ||
            el.value.toLowerCase().includes(inputValue.toLowerCase())
        )
      );
    }
  }, [options, inputValue]);

  const handlePopoverClose = () => setAnchorEl(null);

  return (
    <CheckboxDropdownContainer>
      <StyledLabelText text={label} type="small" />
      <CheckboxDropdownDropdown
        disabled={disabled}
        data-testid={searchFieldLabel}
        onClick={(e: { currentTarget: React.SetStateAction<HTMLElement | null> }) =>
          setAnchorEl(disabled ? null : e.currentTarget)
        }
      >
        {value.length > 0 ? (
          <Stack flexDirection="row" alignItems="center">
            {value.slice(0, 3).map(el => (
              <StyledChip key={el} label={<Text text={el} type="tinyBold" />} color="primary" />
            ))}
            {value.length > 3 && (
              <StyledChip label={<Text text={`+${value.length - 3}`} type="tinyBold" />} color="primary" />
            )}
          </Stack>
        ) : (
          <Text text={placeholder} />
        )}
        {anchorEl ? <ArrowDropUp /> : <ArrowDropDown />}
      </CheckboxDropdownDropdown>
      <StyledPopover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handlePopoverClose}>
        <Stack>
          {searchField && (
            <StyledSearchInput
              searchField
              value={inputValue}
              onChange={handleInputChange}
              onCloseIconClick={handleInputCloseIconClick}
              placeholder={searchFieldLabel}
              fullWidth
              autoFocus
            />
          )}
          {curOptions.length > 0 ? (
            <StyledCheckboxContainer>
              <Checkbox state={value} setState={setValue} options={curOptions} withSelectAll />
            </StyledCheckboxContainer>
          ) : (
            <StyledEmptyList>{constants.noOptionsText}</StyledEmptyList>
          )}
        </Stack>
      </StyledPopover>
    </CheckboxDropdownContainer>
  );
};
