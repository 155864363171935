import React, { useEffect, useState } from 'react';

import { ClearRounded } from '@mui/icons-material';

import { StyledButton, StyledInput, StyledLoader, StyledText } from '@/components/lib';
import { NoDataAvailable } from '@/components/shared/NoDataAvailable/NoDataAvailable';
import {
  ViewSamplesButtonsContainer,
  ViewSamplesTableRow,
  ViewSamplesContainer,
  ViewSamplesTableContainer,
  DialogFlexRowGapContainer,
  ViewSamplesLoaderContainer
} from '../../SamplesGeneration.styes';

import { getAppStorage } from '@/helpers/utils/localStorage';
import { downloadServerFile } from '@/helpers/utils/downloadFile';
import {
  downloadSelectedGeneratedSamplesApiV1GoldensetGeneratorTaskTaskIdDownloadPost,
  generateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePost
} from '@/helpers/services/api';

import { constants } from '../../samplesGeneration.constants';

interface ViewSamplesProps {
  taskId: string;
  guidelines: string;
  description?: string;
}

export const ViewSamples = ({ taskId, guidelines, description }: ViewSamplesProps) => {
  const [table, setTable] = useState<{ id: number; sample: string }[]>([]);
  const [loading, setLoading] = useState(false);
  const [idsToExclude, setIdsToExclude] = useState<number[]>([]);
  const [isDownloadLoading, setDownloadIsLoading] = useState(false);

  const filteredTable = table[0] ? table?.filter(sample => !idsToExclude.includes(sample?.id)) : [];

  const { appId, type } = getAppStorage();

  const handleDeleteSample = (sampleId: number) => setIdsToExclude([...idsToExclude, sampleId]);

  const handleCsvExport = async () => {
    setDownloadIsLoading(true);

    const samplesIds = filteredTable?.map(sample => sample?.id);

    await downloadSelectedGeneratedSamplesApiV1GoldensetGeneratorTaskTaskIdDownloadPost(taskId, samplesIds).then(res =>
      downloadServerFile(res, constants.viewSamples.downloadedFileName)
    );

    setDownloadIsLoading(false);
  };

  const handleGenerate = async () => {
    setLoading(true);

    const res = await generateSamplesApiV1GoldensetGeneratorTaskTaskIdGeneratePost(
      taskId,
      {
        guidelines: guidelines,
        description: description
      },
      { application_id: appId, env_type: type }
    );

    if (res && res[0]) {
      setTable(res as { id: number; sample: string }[]);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => void handleGenerate(), []);

  return (
    <ViewSamplesContainer>
      <StyledInput value={guidelines} label={constants.inputs.guideline.name} disabled />
      <ViewSamplesTableContainer data-testid="ViewSamplesTableContainer">
        {!filteredTable?.length && !loading ? (
          <NoDataAvailable />
        ) : (
          <>
            {filteredTable?.map(sample => (
              <ViewSamplesTableRow key={sample?.id}>
                <StyledText text={sample?.sample} />
                <ClearRounded onClick={() => handleDeleteSample(sample?.id)} />
              </ViewSamplesTableRow>
            ))}
            {loading && !filteredTable?.length && (
              <ViewSamplesLoaderContainer>
                <StyledLoader />
              </ViewSamplesLoaderContainer>
            )}
          </>
        )}
      </ViewSamplesTableContainer>
      <DialogFlexRowGapContainer data-testid="DialogFlexRowGapContainer">
        <StyledText text={constants.viewSamples.numberOfSamples(filteredTable?.length)} type="h2" />
        <ViewSamplesButtonsContainer>
          <StyledButton
            label={constants.viewSamples.generateMore}
            onClick={handleGenerate}
            variant="outlined"
            disabled={loading}
            loading={loading && !!filteredTable?.length}
          />
          <StyledButton label={constants.viewSamples.exportCsv} onClick={handleCsvExport} loading={isDownloadLoading} />
        </ViewSamplesButtonsContainer>
      </DialogFlexRowGapContainer>
    </ViewSamplesContainer>
  );
};
