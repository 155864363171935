import React, { useEffect, useState } from 'react';

import Head from 'next/head';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';

import { QueryClient, QueryClientProvider } from 'react-query';

import { Layout } from '@/components/Layout/Layout';
import { GlobalPopUp } from '@/components/shared/GlobalPopUp/GlobalPopUp';

import { StyledThemeProvider } from '@/components/lib';

import { routes } from '@/helpers/routes';
import { getAppLastUpdateTime } from '@/helpers/services/api';
import { usePollingIndicator } from '@/helpers/hooks/usePolling';
import { getAppStorage, getUserPreferences, setStorageItem, storageKeys } from '@/helpers/utils/localStorage';

import '../components/lib/assets/css/fonts.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const constants = {
  title: 'Deepchecks LLM Evaluation',
  timeout: 25000
};

export default function App({ Component, pageProps }: AppProps) {
  const { type, versionId } = getAppStorage();
  const { lightBar, darkMode: darkModeStorage, activeEnvs } = getUserPreferences();

  const [darkMode, setDarkMode] = useState(darkModeStorage);

  const { pathname } = useRouter();
  const { app_latest_update_time } = usePollingIndicator();

  const isPollingEnabled = pathname === routes.overview;

  const updateRefetchValue = async () => {
    if (versionId && type) {
      await getAppLastUpdateTime({
        application_version_id: versionId,
        env_type: type
      }).then(
        res =>
          res?.app_latest_update_time !== Number(app_latest_update_time) && setStorageItem(storageKeys.polling, res)
      );
    }
  };

  useEffect(() => {
    if (isPollingEnabled) {
      updateRefetchValue();

      const checkRefetch = setInterval(() => updateRefetchValue(), constants.timeout);

      return () => clearInterval(checkRefetch);
    }
  }, [storageKeys.polling, isPollingEnabled]);

  useEffect(() => {
    setStorageItem(storageKeys.userPreferences, {
      lightBar,
      darkMode,
      activeEnvs: activeEnvs
    });
  }, [darkMode]);

  if (
    typeof window !== 'undefined' &&
    window?.location !== window?.parent?.location &&
    !window.parent?.location.hash.includes('file=cypress')
  ) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Head>
        <link rel="manifest" href="/manifest.json" />
        <title>{constants.title}</title>
      </Head>
      <StyledThemeProvider darkMode={darkMode}>
        <GlobalPopUp />
        <Layout darkMode={darkMode} setDarkMode={setDarkMode}>
          <Component {...pageProps} />
        </Layout>
      </StyledThemeProvider>
    </QueryClientProvider>
  );
}
