import { useState, useEffect } from 'react';

import { getStorageItem, storageKeys } from '../utils/localStorage';

import { DeepchecksLlmEvalApiV1GeneralRecordIdentifierSchema } from '../services/api';

const timeout = 60000;

export const usePollingIndicator = () => {
  const getCurrentInfo = () => getStorageItem(storageKeys?.polling);

  const [sysInfo, setSysInfo] = useState(getCurrentInfo());

  useEffect(() => {
    const checkRefetch = setInterval(() => setSysInfo(getCurrentInfo()), timeout);

    return () => clearInterval(checkRefetch);
  }, [sysInfo]);

  return { ...sysInfo } as DeepchecksLlmEvalApiV1GeneralRecordIdentifierSchema;
};
