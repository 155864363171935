import Select from '@mui/material/Select';
import { styled, MenuProps, Box, Stack, Theme, PaletteOptions } from '@mui/material';

import { paletteOptions } from '../../theme/palette';
import { Text } from '../Text/Text';

const StyledSelect = styled(Select)(({ theme }) => ({
  color: theme.palette.grey[500],
  backgroundColor: theme.palette.grey[200],
  borderRadius: '8px',
  border: 'none',
  fontSize: '16px',

  '& .MuiSelect-select': {
    padding: '9px 16px 7px'
  },

  '& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },

  '& svg': {
    color: 'inherit'
  }
}));

const menuProps = (searchField: boolean, whiteBg?: boolean, minWidth?: string): Partial<MenuProps> => ({
  PaperProps: {
    style: {
      maxHeight: '300px',
      backgroundColor: whiteBg ? '#FFF' : paletteOptions.grey?.[200],
      borderRadius: '8px',
      marginTop: '8px',
      minWidth: minWidth || '150px',
      padding: '0 8px'
    }
  },
  MenuListProps: {
    style: {
      paddingBottom: 0,
      paddingTop: searchField ? 0 : '8px'
    },
    sx: {
      '& li': {
        padding: '8px'
      }
    }
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  }
});

const StyledStickyContainer = styled(Box)<{ is_white_bg?: string }>(({ is_white_bg }) => ({
  zIndex: 1,
  top: 0,
  padding: '8px 0',
  position: 'sticky',
  backgroundColor: is_white_bg === 'true' ? 'white' : paletteOptions.grey?.[200]
}));

const StyledBottomContainer = styled(Box)<{ is_white_bg?: string; is_no_border?: string; is_no_data?: string }>(
  ({ is_white_bg, is_no_border, is_no_data }) => ({
    zIndex: 3,
    bottom: 0,
    margin: 0,
    position: 'sticky',
    padding: is_no_data === 'true' ? '8px 0' : '0 0 8px',
    backgroundColor: is_white_bg === 'true' ? 'white' : paletteOptions.grey?.[200],
    borderTop: is_no_border === 'true' ? 'none' : `2px solid ${paletteOptions.grey?.[300]}`,
    '& > div': {
      justifyContent: 'flex-start'
    }
  })
);

const StyledBottomTextContainer = styled(Box)(({ theme }) => ({
  height: '41px',
  gap: '6px',
  display: 'flex',
  padding: '6px 0 6px 8px',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',

  '.MuiTypography-root': { color: theme.palette.primary.main },
  '.MuiSvgIcon-root': { color: paletteOptions.grey?.[400], fontSize: '20px', marginLeft: '-2px' }
}));

const StyledValueContainer = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  gap: '4px'
});

const StyledText = styled(Text)(({ theme }) => ({
  color: theme.palette.grey[400]
}));

const StyledPlaceholderText = styled(Text)(({ theme }) => ({
  color: theme.palette.grey[300]
}));

const sxStyles = (theme: Theme) => ({
  width: 1,
  color: theme.palette.grey[500],

  '&::before, ::after': {
    borderColor: theme.palette.grey[500],
    opacity: 0.3,

    '&:hover': {
      borderColor: theme.palette.grey[500]
    }
  }
});

const sxDropdownItemFistTestStyles = (paletteOptions: PaletteOptions) => ({ color: paletteOptions.grey?.[500] });
const sxDropdownItemSecondTestStyles = (paletteOptions: PaletteOptions) => ({
  marginLeft: 'auto',
  color: paletteOptions.grey?.[400]
});
const sxDropdownItemThirdTestStyles = (paletteOptions: PaletteOptions) => ({
  marginLeft: 'auto',
  color: paletteOptions.grey?.[500]
});
const sxDropdownItemFourthTestStyles = (theme: Theme) => ({
  color: theme.palette.grey[500],
  margin: '2px 16px 8px 16px'
});
const sxDropdownItemTestStyles = (paletteOptions: PaletteOptions, name: string, selectedValue?: string) => ({
  marginRight: '20px',
  color:
    selectedValue === name
      ? paletteOptions.primary?.['main' as keyof typeof paletteOptions.primary]
      : paletteOptions.grey?.[500]
});
const sxOutlinedDropdownStyles = (theme: Theme) => ({
  height: '42px',
  borderRadius: '5px',

  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '1px'
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.grey[300],
    borderWidth: '2px'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.grey[300]
  },
  '.MuiInputBase-input': {
    color: theme.palette.grey[600]
  }
});

export {
  StyledSelect,
  menuProps,
  StyledStickyContainer,
  StyledBottomContainer,
  StyledBottomTextContainer,
  StyledValueContainer,
  StyledText,
  StyledPlaceholderText,
  sxStyles,
  sxDropdownItemFistTestStyles,
  sxDropdownItemSecondTestStyles,
  sxDropdownItemThirdTestStyles,
  sxDropdownItemFourthTestStyles,
  sxDropdownItemTestStyles,
  sxOutlinedDropdownStyles
};
