/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { Box, BoxProps } from '@mui/material';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  ChartData,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';

import { Text } from '../../Text/Text';

import { StyledLegendsContainer } from './ComboChart.styles';

import { getComboData, getComboOptions } from './comboChart.helpers';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  annotationPlugin,
  Legend
);

interface ComboChartProps extends BoxProps {
  rawData?: any;
  barData: number[][];
  barHeight?: string;
  barWidth?: string;
  lineData: number[];
  barLegends: string[];
  labels: string[] | number[];
}

export const ComboChart = (props: ComboChartProps) => {
  const {
    labels,
    rawData,
    barData,
    lineData,
    barLegends,
    barHeight = 'auto',
    barWidth = 'auto',
    ...otherProps
  } = props;

  const maxBarValue = Array?.isArray(barData)
    ? Math.max(...barData.map(data => data.reduce((acc, value) => acc + value, 0)))
    : NaN;

  return (
    <Box
      {...otherProps}
      sx={{
        width: '100%',
        canvas: { height: barHeight, maxHeight: barHeight, width: barWidth, maxWidth: barWidth, margin: 'auto' }
      }}
    >
      <Bar
        data={
          getComboData(barData, lineData, labels as string[], barLegends, maxBarValue) as ChartData<
            'bar',
            number[],
            string
          >
        }
        options={getComboOptions(rawData, maxBarValue) as any}
      />
      <StyledLegendsContainer>
        {Array.isArray(barLegends) ? (
          barLegends.map((legend, i) => <Text key={i} text={legend} type="bodyBold" />)
        ) : (
          <></>
        )}
      </StyledLegendsContainer>
    </Box>
  );
};
