import { TypographyOptions } from '@mui/material/styles/createTypography';

import { paletteOptions } from './palette';

export const typographyOptions: TypographyOptions = {
  fontFamily: 'Manrope, sans-serif',
  h1: {
    fontSize: '32px',
    fontWeight: 600,
    lineHeight: '40px',
    color: paletteOptions.grey?.[500],

    '@media (max-width:1536px)': {
      fontSize: '24px',
      lineHeight: '28px'
    }
  },
  h2: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '32px',
    color: paletteOptions.grey?.[500],

    '@media (max-width:1536px)': { fontSize: '20px' }
  },
  h3: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '24px',
    color: paletteOptions.grey?.[600],

    '@media (max-width:1536px)': { fontSize: '16px' }
  },
  h5: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    color: paletteOptions.grey?.[500],

    '@media (max-width:1536px)': { fontSize: '12px' }
  },
  h6: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '14.1px',

    '@media (max-width:1536px)': { fontSize: '10px' }
  },
  body1: {
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '21px',
    color: paletteOptions.grey?.[500],

    '@media (max-width:1536px)': { fontSize: '14px' }
  },
  body2: {
    fontSize: '15px',
    fontWeight: 700,
    lineHeight: '21px',
    color: paletteOptions.grey?.[400],

    '@media (max-width:1536px)': { fontSize: '14px' }
  },
  subtitle1: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14.4px',
    color: paletteOptions.grey?.[400],

    '@media (max-width:1536px)': { fontSize: '10px' }
  },
  subtitle2: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '14.4px',
    color: paletteOptions.grey?.[400],

    '@media (max-width:1536px)': { fontSize: '10px' }
  },
  button: {
    fontSize: '15px',
    fontWeight: 700,
    lineHeight: '21px',
    textTransform: 'none',

    '@media (max-width:1536px)': { fontSize: '14px' }
  }
};
