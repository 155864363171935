import React, { useState } from 'react';

import { Button } from '@mui/material';
import { InsertDriveFileOutlined, LinkOutlined } from '@mui/icons-material';

import { DataContextItem } from './DataContextItem/DataContextItem';
import { StyledButton, StyledInput, StyledText } from '@/components/lib';
import {
  SamplesGenerationDialogContentContainer,
  DialogButtonsContainer,
  GenerateSamplesSubmitBtn
} from '../../SamplesGeneration.styes';

import {
  addSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePost,
  deleteSourceApiV1GoldensetGeneratorTaskTaskIdDataSourceSourceIdDelete
} from '@/helpers/services/api';
import { validateLink } from '@/helpers/utils/validateLink';

import { sxStyles } from './GenerateSamples.styles';

import { constants } from '../../samplesGeneration.constants';

interface GenerateSamplesProps {
  taskId: string;
  payload: { description: string; guidelines: string };
  setPayload: (payload: { description: string; guidelines: string }) => void;
  handleChangeView: () => void;
}
interface GenerateSamplesDataContext {
  src?: File;
  id: number | string;
  type: string;
  status: string;
}

export const GenerateSamples = ({ taskId, payload, setPayload, handleChangeView }: GenerateSamplesProps) => {
  const [dataContext, setDataContext] = useState<GenerateSamplesDataContext[]>([]);
  const [failedDataContext, setFailedDataContext] = useState<GenerateSamplesDataContext[]>([]);
  const [linkInput, setLinkInput] = useState({ show: false, input: '' });

  const checkStatus = () => {
    for (let i = 0; i < dataContext.length; i++) {
      if (dataContext[i].status === 'load') {
        return true;
      }
    }
    return false;
  };

  const handlePayloadChange = (field: string, value: string) => setPayload({ ...payload, [field]: value });

  const handleAddDataContext = async (src: File | undefined, link?: boolean) => {
    const newId = dataContext.length + 1;
    const srcPayload = link ? { url_to_crawl: src?.name } : { file: src };

    setDataContext([...dataContext, { src: src, id: newId, type: link ? 'link' : 'file', status: 'load' }]);

    const addContext = (status: 'success' | 'failed') => {
      setDataContext(prevDataContext => {
        const updatedDataContext = prevDataContext.map(context => {
          if (context.id === newId) {
            return { ...context, status: status };
          } else {
            return context;
          }
        });

        return updatedDataContext;
      });
    };

    const handleFailedSrc = () => {
      setDataContext(dataContext.filter(context => context?.id !== newId));
      setFailedDataContext([
        ...failedDataContext,
        { src: src, id: newId, type: link ? 'link' : 'file', status: 'failed' }
      ]);
    };

    const res = await addSourceApiV1GoldensetGeneratorTaskTaskIdDataSourcePost(taskId, srcPayload);

    res && res.data_context && res.data_context[1] ? addContext('success') : handleFailedSrc();
  };

  const handleDeleteClick = (id: string) => {
    deleteSourceApiV1GoldensetGeneratorTaskTaskIdDataSourceSourceIdDelete(taskId, id).then(() =>
      setDataContext(dataContext.filter(context => context?.id !== id))
    );
  };

  const disabledSubmitButton = !payload.description || !payload.guidelines || checkStatus();
  const disabledAddLink =
    !!dataContext?.filter(c => linkInput?.input === c?.src?.name)[0] ||
    !linkInput?.input ||
    !!failedDataContext?.filter(c => linkInput?.input === c?.src?.name)[0] ||
    !validateLink(linkInput?.input);

  return (
    <SamplesGenerationDialogContentContainer>
      <StyledText text={constants.dialogDescription} />
      <StyledInput
        label={constants.inputs.guideline.label}
        value={payload.guidelines}
        onChange={e => handlePayloadChange('guidelines', e.target.value)}
        placeholder={constants.inputs.guideline.placeholder}
        rows={4}
        multiline
      />
      <StyledInput
        label={constants.inputs.description.label}
        value={payload.description}
        onChange={e => handlePayloadChange('description', e.target.value)}
        placeholder={constants.inputs.description.placeholder}
        rows={4}
        multiline
      />
      <StyledText text={constants.inputs.dataContext.label} type="tinyBold" />
      <DialogButtonsContainer>
        <Button variant="outlined" startIcon={<LinkOutlined />} onClick={() => setLinkInput({ show: true, input: '' })}>
          {constants.inputs.dataContext.options.link}
        </Button>
        <Button variant="outlined" component="label" startIcon={<InsertDriveFileOutlined />}>
          {constants.inputs.dataContext.options.file}
          <input type="file" onChange={e => handleAddDataContext(e.target?.files?.[0])} hidden />
        </Button>
      </DialogButtonsContainer>
      {linkInput.show && (
        <StyledInput
          value={linkInput.input}
          placeholder={constants.inputs.link.label}
          onChange={e => setLinkInput({ show: true, input: e.target.value })}
          fullWidth
          endAdornment={
            <StyledButton
              label="&#8594;"
              onClick={() => handleAddDataContext({ name: linkInput.input } as File, true)}
              sx={sxStyles}
              disabled={disabledAddLink}
            />
          }
        />
      )}
      {dataContext.map((context, i) => (
        <DataContextItem key={i} context={context} handleDeleteClick={() => handleDeleteClick(context?.id as string)} />
      ))}
      {failedDataContext.map((context, i) => (
        <DataContextItem key={i} context={context} handleDeleteClick={() => handleDeleteClick(context?.id as string)} />
      ))}
      <GenerateSamplesSubmitBtn
        label={constants.submitButtonLabel}
        onClick={handleChangeView}
        disabled={disabledSubmitButton}
        data-testid="GenerateSamplesSubmitBtn"
      />
    </SamplesGenerationDialogContentContainer>
  );
};
